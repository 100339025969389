import React from 'react'
import styles from './iconHoverAnimation'
import withStyles from '@material-ui/core/styles/withStyles'

const Twitter = ({classes, fill}) => (
  <div className={classes.icon}>
    <svg enableBackground="new 0 0 48 48" id="Layer_1" version="1.1" viewBox="0 0 48 48"
         xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill={fill}/>
      <g>
        <g>
          <path
            d="M36.8,15.4c-0.9,0.5-2,0.8-3,0.9c1.1-0.7,1.9-1.8,2.3-3.1c-1,0.6-2.1,1.1-3.4,1.4c-1-1.1-2.3-1.8-3.8-1.8    c-2.9,0-5.3,2.5-5.3,5.7c0,0.4,0,0.9,0.1,1.3c-4.4-0.2-8.3-2.5-10.9-5.9c-0.5,0.8-0.7,1.8-0.7,2.9c0,2,0.9,3.7,2.3,4.7    c-0.9,0-1.7-0.3-2.4-0.7c0,0,0,0.1,0,0.1c0,2.7,1.8,5,4.2,5.6c-0.4,0.1-0.9,0.2-1.4,0.2c-0.3,0-0.7,0-1-0.1    c0.7,2.3,2.6,3.9,4.9,3.9c-1.8,1.5-4.1,2.4-6.5,2.4c-0.4,0-0.8,0-1.3-0.1c2.3,1.6,5.1,2.6,8.1,2.6c9.7,0,15-8.6,15-16.1    c0-0.2,0-0.5,0-0.7C35.2,17.6,36.1,16.6,36.8,15.4z"
            fill="#FFFFFF"/>
        </g>
      </g>
    </svg>
  </div>
);

export default withStyles(styles)(Twitter)
