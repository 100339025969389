import React from 'react'

const Logo = ({className}) => (
  <div>
  <svg width="200px" height="200px" version="1.1" xmlns="http://www.w3.org/2000/svg" className={className}
       style={{"fillRule":"evenodd", "clipRule": "evenodd", "strokeLinejoin": "round", "strokeMiterlimit": "1.41421"}}>
    <circle className="logo-circle" cx="100.393" cy="99.816" r="100"/>
    <path className={"logo-letter"}
      d="M58.196,55.432l23.985,0.207l-0.142,94.276l-23.843,0.037l0,-94.52Zm13.991,87.823l0,-80.944l-2.89,0c-0.085,-0.13 -0.232,-0.21 -0.39,-0.21c-0.253,0 -0.461,0.203 -0.462,0.451l0,80.774l3.742,-0.071Z"
     />
    <path
      d="M165.086,90.576c0.167,5.851 -1.02,11.662 -3.468,16.978c-2.182,4.476 -5.433,8.348 -9.465,11.271c-4.223,2.993 -8.981,5.151 -14.016,6.357c-5.673,1.392 -11.498,2.071 -17.339,2.023l0,22.975l-20.835,0l0,-95.015l20.835,0c5.841,-0.047 12.665,0.128 17.587,1.28c5.19,1.214 9.544,2.745 13.768,5.738c4.041,2.943 7.293,6.84 9.465,11.343c2.459,5.338 3.646,11.175 3.468,17.05Zm-10.016,0c0.092,-3.876 -0.673,-7.725 -2.24,-11.271c-1.416,-3.081 -3.576,-5.763 -6.286,-7.802c-2.885,-2.113 -6.146,-3.657 -9.608,-4.552c-3.94,-1.015 -7.998,-1.501 -12.066,-1.445l-16.328,0l0,78.027l3.757,0l0,-27.815l12.571,0c4.068,0.057 8.126,-0.429 12.066,-1.445c3.462,-0.895 6.723,-2.439 9.608,-4.551c2.71,-2.04 4.87,-4.722 6.286,-7.803c1.553,-3.574 2.293,-7.448 2.167,-11.343l0.073,0Zm-4.046,0c0.119,3.591 -0.624,7.159 -2.168,10.404c-1.335,2.667 -3.324,4.953 -5.779,6.646c-2.525,1.688 -5.343,2.889 -8.309,3.54c-3.247,0.736 -6.568,1.099 -9.898,1.084l-12.571,0l0,-43.348l12.571,0c3.33,-0.015 6.651,0.348 9.898,1.084c2.966,0.652 5.784,1.853 8.309,3.54c2.454,1.694 4.443,3.98 5.779,6.646c1.543,3.245 2.286,6.813 2.168,10.404Zm-26.154,10.693l1.301,0c4.046,0 6.936,-0.94 8.669,-2.818c3.53,-4.639 3.53,-11.111 0,-15.75c-1.782,-1.878 -4.672,-2.842 -8.669,-2.89l-1.301,0l0,21.458Z"
      className={"logo-letter"}/>
  </svg>
  </div>
);

export default Logo
